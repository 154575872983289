.home-intro {

	background-color: $yellow;

	&__image {
	    
	    @include media-breakpoint-up (xl) {
	    	margin-left: -20px;
	    	margin-top: -140px;
	    }

	    @include media-breakpoint-up (xxl) {
	    	margin-top: -200px;
	    }
	}
}