.site-footer {

  padding-bottom: $spacer*2;

	background-color: $gray;
  color: #fff;
  text-align: center;

  &__logo {

    margin-bottom: 10px;
  }

  &__copyright {

    font-size: 12px;
  }
}