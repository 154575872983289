/*
|--------------------------------------------------------------------------
| Base Typography
|
| These styles are used to set a base style for all elements. Only use
| these properties here:
|
| color:
| font-family:
| font-size:
| line-height:
| margin:
|
|--------------------------------------------------------------------------
*/

h1 {

    line-height: 1.13;

    @include media-breakpoint-down(sm) {
        font-size: calc(#{$font-size-h1}/2.75 + 4vw);
    }

    @include media-breakpoint-up(xl) {
        font-size: em(64);
    }

    @include media-breakpoint-up(xxl) {
        font-size: em(72);
    }
}

h2 {

    @include media-breakpoint-down(sm) {
        font-size: calc(#{$font-size-h2}/2 + 2vw);
    }

    @include media-breakpoint-up(xl) {
        font-size: em(40);
    }

    @include media-breakpoint-up(xxl) {
        font-size: em(48);
    }
}

h3 {

    @include media-breakpoint-down(sm) {
        font-size: calc(#{$font-size-h3}/2 + 2vw);
    }

    @include media-breakpoint-up(xl) {
        font-size: em(30);
    }

    @include media-breakpoint-up(xxl) {
        font-size: em(38);
    }
}

.lead {

    line-height: 1.6;
}

h4, h5, h6 {

}

p {

}

ul {

}

ol {

}

li {

}

b, strong {

}

i, em {

}

quote, blockquote {

}