.themes-callout {

  color: $gray-dark;

  &__images {

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // text-align: center;
  }
}

&__image {

    margin-bottom: 0;
    // width: 100%;
    // height: auto;
}

&__image-wrap {
  &--half {
        padding: 0 1% 0 0;
        width: 25%;


      @include media-breakpoint-down (md) {
          display: none;
      }
  }

    &--last {
        margin-left: auto;
        padding-left: 1%;
        padding-right: 0;
    }

  &--full {
        padding: 0 3.75%;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
  }
}
}