/*
|--------------------------------------------------------------------------
| Buttons
|
| Various button styles that can be used. Apply this class directly
| in HTML. Then BEM syntax can override if needed.
|
| Example:
|
| <div class="block">
|     <a class="block__button button" href=""></a>
| </div>
|
|--------------------------------------------------------------------------
*/

.btn {
    // -webkit-font-smoothing: antialiased;

    // padding-top: $input-btn-padding-y-top;
    // padding-bottom: $input-btn-padding-y-bottom;

    // color: #fff;
    // font-weight: 300;
    cursor: pointer;
    text-decoration: none;
}

.btn-lg {


	letter-spacing: .25px;

	@include media-breakpoint-down(xs) {
		// font-size: 14px;
	}

	@include media-breakpoint-up(xl) {
		// font-size: 24px;
	}
}

.btn-success {
    color: #fff;

    &:hover {
        color: #fff;
    }
}