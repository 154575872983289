.contact-info {

&--contact-page {


        margin-bottom: calc(#{$spacer} + 2vh);
}

    &__title {


    }

    &__data {


    }
}