.theme-page {

	background-color: $gray-light;
	background-image: linear-gradient(-45deg, $gray-700, $gray-700 + 30, $gray-700);
	color: #fff;

	&--stoney-creek {
    	background: linear-gradient(-45deg, #48423C 0%, #9A917D + 30 65%, #9A917D 100%);
		}

	&--green-acres {
    	background: linear-gradient(-45deg, #046302 - 20 0%, #4d9602 + 10 65%, #4d9602 - 10 100%);
		}

	&--caledon-light {
	    background: linear-gradient(-45deg, $gray + 20 0%, $gray + 80 65%, $gray + 30 100%);
		}

	&--caledon-dark {
	    background: linear-gradient(-45deg, $gray-dark + 30 0%, $gray-dark + 75 65%, $gray-dark + 30 100%);
		}

	&--newport-light {
	    background: linear-gradient(-45deg, $gray-light - 5 0%, $gray-light + 70 65%, $gray-light + 30 100%);
		}

	&--newport-dark {
	    background: linear-gradient(-45deg, #ffb400 - 30, #ffb400 + 15 65%, #ffb400 + 10 100%);
		}

	&--service-pro {
	    background: linear-gradient(-45deg, #2F92CB - 70 0%, #2F92CB + 10 65%, #2F92CB - 20 100%);
		}

	&--burlington {
	    background: linear-gradient(-45deg, #1d4997 - 20, #1d4997 + 50 65%, #1d4997 + 2 100%);
		}

	&--richmond-light {
	    background: linear-gradient(-45deg, #9d9892 - 30, #9d9892 + 40 65%, #9d9892 - 10);
		}

	&--richmond-dark {
	    background: linear-gradient(-45deg, #2c251f - 20, #2c251f + 50 65%, #2c251f + 20);
		}

	&--soho-light {
	    background: linear-gradient(-45deg, #e5e3d7 - 90, #e5e3d7 - 30 65%, #e5e3d7 - 60);
		}

	&--soho-dark {
	    background: linear-gradient(-45deg, #9c8772 - 60, #9c8772 + 30 65%, #9c8772 + 0);
		}

	&--white {
	    background: transparent;
	}
}