.site-header {

    position: relative;

    background-color: $gray;
    color: #fff;

    &__inner {

        @include list-unstyled;

        align-items: center;
        display: flex;
        justify-content: space-between;

        padding: $spacer 0;
        width: 100%;

        text-align: center;


        @include media-breakpoint-up(md){
            align-items: baseline;
        }
    }

    &__logo-wrap {

        left: -10px;
        position: relative;
        z-index: 20;

        order: 2;

        display: inline-block;
        margin-left: $spacer/2.5;
        margin-right: auto;
        width: 136px;

        @media (min-width: 440px) {
            margin-left: $spacer;
        }

        @include media-breakpoint-up(md){
            order: 1;
            flex: 0 0 136px;
        }

        @include media-breakpoint-up(lg){
            margin-right: 0;

            flex: 0 0 200px
        }
    }

    &__logo {

    }

    &__tagline {
        position: relative;
        top: -$spacer/2;

        order: 2;

        flex: 0 0 200px;
        margin-right: auto;

        color: $gray-lightest;
        font-weight: 800;
        font-size: 13px;
        text-align: left;
        text-transform: uppercase;


        @include media-breakpoint-down(md){
            display: none;
        }
    }

    &__button {

        position: relative;
        z-index: 20;

        order: 3;

        @include media-breakpoint-down(xs){
            font-size: 14px;
        }

        @media (max-width: 369px) {
            height: 33px;
            padding: 1em 1em;

            font-size: 12px;
            line-height: .9;
        }


        @include media-breakpoint-between(md, lg) {
            font-size: 14px;

        }

    }

}