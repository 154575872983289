.slick-dots {
	position: relative;
	top: -30px;

	li {
		margin: 0 8px;
	}

	li button::before {
		color: #fff !important;
		font-size: 10px;
	}
}

.slick-dotted.slick-slider {
	margin-bottom: 0 !important;
}