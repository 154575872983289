.home-hero {

  padding: $spacer-2 0;

  background-color: $gray-700;
  background-image: url(/img/home-hero.jpg);
  background-size: cover;
  background-position: center top;
  color: #fff;

@media 
(-webkit-min-device-pixel-ratio: 2) and (min-width: 768px), 
(min-resolution: 192dpi) and (min-width: 768px) { 
    background-image: url(/img/home-hero@2x.jpg);
}

  &__heading {

  }

  &__copy {

  }

  &__button {

  }
}
