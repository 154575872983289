// Bootstrap customizations

$blue:                      #00A3DA;
$gray:                      #2c2c2c;
$gray-dark:                 darken($gray, 10);
$gray-light:                #646464;
$gray-lighter:              #E8E7E4;
$gray-lightest:             lighten($gray, 30);
$green:                     #8bc932;
$yellow:                    #FED347;
$mustard:                   #FAAD16;
$red:                       #ff0000;

$brand-primary:             $yellow;
$brand-success:             $green;
$brand-info:                $blue;
$brand-warning:             $yellow;
$brand-danger:              $yellow;
$brand-inverse:             $gray-dark;



$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $blue,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.


$spacer:   1rem;
$spacer-x:   1rem;
$spacer-y:   1rem;
$spacer-1: $spacer * .25;
$spacer-2: $spacer * .75;
$spacer-3: $spacer;
$spacer-4: $spacer * 2;
$spacer-5: $spacer * 4;
$spacer-6: $spacer * 6;
$spacer-7: $spacer * 8;
$spacer-8: $spacer * 12;

$spacers: (
  0:0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
  8: $spacer-8
);

$border-width: 4px;


// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff;
$body-color: $gray;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1640px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 719px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width-base:     30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base / 2,
  sm: $grid-gutter-width-base / 2,
  md: $grid-gutter-width-base / 2,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base,
  xxl: $grid-gutter-width-base
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;

$font-size-base: 1rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

$line-height-base: 1.6;

$line-height-sm: $line-height-base * .9;
$line-height-lg: $line-height-base * 1.25;

$font-size-h1: 4.5rem;
$font-size-h2: 3rem;
$font-size-h3: 1.25rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   inherit;
$headings-font-weight:   300;
$headings-line-height:   1.22;
$headings-color:         inherit;

$lead-font-size:   1.5rem;
$lead-font-weight: 400;

$small-font-size: 80%;

$text-muted: $gray-light;

$abbr-border-color: $gray-light;

$blockquote-small-color:  $gray-light;
$blockquote-font-size:    ($font-size-base * 1.5);
$blockquote-border-color: $gray-lighter;
$blockquote-border-width: .25rem;

$hr-border-color: rgba(0,0,0,.1);
$hr-border-width: $border-width;

$mark-padding: .2em;

$dt-font-weight: bold;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25);
$nested-kbd-font-weight: bold;

$list-inline-padding: 20px;


// radius

$border-radius:          0;
$border-radius-lg:       0;
$border-radius-sm:       0;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .3rem;

$table-bg:                      transparent;
$table-bg-accent:               rgba(0,0,0,.05);
$table-bg-hover:                rgba(0,0,0,.075);
$table-bg-active:               $table-bg-hover;

$table-border-width:            $border-width;
$table-border-color:            $gray-lighter;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-x:                  1rem;
$input-btn-padding-y:                  .25rem;
$input-btn-padding-y-bottom:           $input-btn-padding-y;
$input-btn-padding-y-top:              $input-btn-padding-y;
$input-btn-line-height:                1.5;
$input-btn-font-weight:                600;
$input-btn-box-shadow:                 none;
$input-btn-active-box-shadow:          none;

$input-btn-primary-color:              #fff;
$input-btn-primary-bg:                 $brand-primary;
$input-btn-primary-border:             $input-btn-primary-bg;

$input-btn-secondary-color:            #fff;
$input-btn-secondary-bg:               $gray;
$input-btn-secondary-border:           $gray;

$input-btn-info-color:                 #fff;
$input-btn-info-bg:                    $brand-info;
$input-btn-info-border:                $input-btn-info-bg;

$input-btn-success-color:              #fff;
$input-btn-success-bg:                 $brand-success;
$input-btn-success-border:             $input-btn-success-bg;

$input-btn-warning-color:              #fff;
$input-btn-warning-bg:                 $brand-warning;
$input-btn-warning-border:             $input-btn-warning-bg;

$input-btn-danger-color:               #fff;
$input-btn-danger-bg:                  $brand-danger;
$input-btn-danger-border:              $input-btn-danger-bg;

$input-btn-link-disabled-color:        $gray-light;

$input-btn-padding-x-sm:               1.25em;
$input-btn-padding-y-sm:               .5em;
$input-btn-line-height-sm: 1;

$input-btn-padding-x-lg:               1.5rem;
$input-btn-padding-y-lg:               .575rem;
$input-btn-line-height-lg: 1;

$input-btn-block-spacing-y:            .5em;
$input-btn-toolbar-margin:             .5em;

// Allows for customizing button radius independently from global border radius
$input-btn-border-radius:              0;
$input-btn-border-radius-lg:           0;
$input-btn-border-radius-sm:           0;

$input-height-inner-sm: 1rem;
$input-height-border: 1rem;
$input-height-inner-lg: 1rem;

// Forms

$input-padding-x:                .75rem;
$input-padding-y:                .5rem;
$input-line-height:              1.25;

$input-bg:                       #fff;
$input-bg-disabled:              $gray-lighter;

$input-color:                    $gray-dark;
$input-border-color:             rgba(0,0,0,.15);
$input-btn-border-width:         1px;
$input-box-shadow:               0;

$input-border-radius:            0;
$input-border-radius-lg:         0;
$input-border-radius-sm:         0;

$input-bg-focus:                 $input-bg;
$input-border-focus:             $gray;
$input-box-shadow-focus:         $input-box-shadow, 0 0 8px rgba(102,175,233,.6);
$input-color-focus:              $input-color;

$input-color-placeholder:        #999;

$input-padding-x:                .5rem;
$input-padding-y:                .25rem;

$input-padding-x-sm:             .5rem;
$input-padding-y-sm:             .25rem;

$input-padding-x-lg:             1.5rem;
$input-padding-y-lg:             .75rem;

$input-height:                   (($font-size-base * $line-height-base) + ($input-padding-y * 2));
$input-height-lg:                (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm:                (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$form-group-margin-bottom:       $spacer-y;

$input-group-addon-bg:           $gray-lighter;
$input-group-addon-border-color: $input-border-color;

$cursor-disabled:                not-allowed;

$custom-control-gutter:   1.5rem;
$custom-control-spacer-x: 1rem;
$custom-control-spacer-y: .25rem;

$custom-control-indicator-size:       1rem;
$custom-control-indicator-bg:         #ddd;
$custom-control-indicator-bg-size:    50% 50%;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba(0,0,0,.1);

$custom-control-disabled-cursor:             $cursor-disabled;
$custom-control-disabled-indicator-bg:       #eee;
$custom-control-disabled-description-color:  #767676;

$custom-control-checked-indicator-color:      #fff;
$custom-control-checked-indicator-bg:         #0074d9;
$custom-control-checked-indicator-box-shadow: none;

$custom-control-focus-indicator-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;

$custom-control-active-indicator-color:      #fff;
$custom-control-active-indicator-bg:         #84c6ff;
$custom-control-active-indicator-box-shadow: none;

$custom-checkbox-radius: 0;
$custom-checkbox-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$custom-checkbox-indeterminate-bg: #0074d9;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color;
$custom-checkbox-indeterminate-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E");
$custom-checkbox-indeterminate-box-shadow: none;

$custom-radio-radius: 50%;
$custom-radio-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E");

$custom-select-padding-x:          .75rem ;
$custom-select-padding-y:          .375rem;
$custom-select-indicator-padding:  1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color:          $input-color;
$custom-select-disabled-color: $gray-light;
$custom-select-bg:            #fff;
$custom-select-disabled-bg:   $gray-lighter;
$custom-select-bg-size:       8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: #333;
$custom-select-indicator:     url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
$custom-select-border-width:  $input-btn-border-width;
$custom-select-border-color:  $input-border-color;
$custom-select-border-radius: 0;

$custom-select-focus-border-color: #51a7e8;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(81, 167, 232, .5);

$custom-select-sm-padding-y: .2rem;
$custom-select-sm-font-size: 75%;

$custom-file-height:           2.5rem;
$custom-file-width:            14rem;
$custom-file-focus-box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;

$custom-file-padding-x:     .5rem;
$custom-file-padding-y:     1rem;
$custom-file-line-height:   1.5;
$custom-file-color:         #555;
$custom-file-bg:            #fff;
$custom-file-border-width:  $border-width;
$custom-file-border-color:  #ddd;
$custom-file-border-radius: 0;
$custom-file-box-shadow:    inset 0 .2rem .4rem rgba(0,0,0,.05);
$custom-file-button-color:  $custom-file-color;
$custom-file-button-bg:     #eee;
$custom-file-text: (
  placeholder: (
    en: "Choose file..."
  ),
  button-label: (
    en: "Browse"
  )
);


// Form validation icons
$form-icon-success-color: $brand-success;
$form-icon-success: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");

$form-icon-warning-color: $brand-warning;
$form-icon-warning: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");

$form-icon-danger-color: $brand-danger;
$form-icon-danger: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");


// List group

$list-group-bg:                  $white !default;
$list-group-border-color:        rgba($black,.125) !default;
$list-group-border-width:        1px;
// $list-group-border-radius:       $border-radius !default;

$list-group-item-padding-y:      .75rem !default;
$list-group-item-padding-x:      1.25rem !default;

// $list-group-hover-bg:                 $gray-100 !default;
// $list-group-active-color:             $component-active-color !default;
// $list-group-active-bg:                $component-active-bg !default;
// $list-group-active-border-color:      $list-group-active-bg !default;

// $list-group-disabled-color:      $gray-600 !default;
// $list-group-disabled-bg:         $list-group-bg !default;

// $list-group-action-color:             $gray-700 !default;
// $list-group-action-hover-color:       $list-group-action-color !default;

// $list-group-action-active-color:      $body-color !default;
// $list-group-action-active-bg:         $gray-200 !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990;
$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-navbar-sticky:     1030;
$zindex-modal-bg:          1040;
$zindex-modal:             1050;
