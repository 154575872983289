.nowrap {
  white-space: nowrap;
}

.nowrap-lg {
  @include media-breakpoint-up(lg) {
    white-space: nowrap;
  }
}

.nowrap-xl {
  @include media-breakpoint-up(xl) {
    white-space: nowrap;
  }
}

.nowrap-xxl {
  @include media-breakpoint-up(xxl) {
    white-space: nowrap;
  }
}