.theme-preview {

	background-color: $gray-light;
	background-image: linear-gradient(-45deg, $gray-dark, $gray-dark - 20, $gray-dark);

	&--stoney-creek {
    	background: linear-gradient(-45deg, #48423C 0%, #9A917D + 30 65%, #9A917D 100%);
		}

	&--green-acres {
    	background: linear-gradient(-45deg, #046302 - 20 0%, #4d9602 + 10 65%, #4d9602 - 10 100%);
		}

	&--caledon-light {
	    background: linear-gradient(-45deg, $gray + 20 0%, $gray + 80 65%, $gray + 30 100%);
		}

	&--caledon-dark {
	    background: linear-gradient(-45deg, $gray-dark + 30 0%, $gray-dark + 75 65%, $gray-dark + 30 100%);
		}

	&--newport-light {
	    background: linear-gradient(-45deg, $gray-light - 5 0%, $gray-light + 70 65%, $gray-light + 30 100%);
		}

	&--newport-dark {
	    background: linear-gradient(-45deg, #ffb400 - 30, #ffb400 + 15 65%, #ffb400 + 10 100%);
		}

	&--service-pro {
	    background: linear-gradient(-45deg, #2F92CB - 70 0%, #2F92CB + 10 65%, #2F92CB - 20 100%);
		}

	&--burlington {
	    background: linear-gradient(-45deg, #1d4997 - 20, #1d4997 + 50 65%, #1d4997 + 2 100%);
		}

	&--richmond-light {
	    background: linear-gradient(-45deg, #9d9892 - 30, #9d9892 + 40 65%, #9d9892 - 10);
		}

	&--richmond-dark {
	    background: linear-gradient(-45deg, #2c251f - 20, #2c251f + 50 65%, #2c251f + 20);
		}

	&--soho-light {
	    background: linear-gradient(-45deg, #e5e3d7 - 90, #e5e3d7 - 30 65%, #e5e3d7 - 60);
		}

	&--soho-dark {
	    background: linear-gradient(-45deg, #9c8772 - 60, #9c8772 + 30 65%, #9c8772 + 0);
		}

	&--white {
	    background: transparent;
	}

	&__screenshots {
		position: relative;

		display: flex;
		align-items: flex-end;
		margin-top: $spacer*2;
		overflow: hidden;
		padding: 0 5% 0 26%;

		// height: 350px;

		@include media-breakpoint-up(md){
		    margin-top: $spacer*3;
		}
	}

	&__image--desktop {

		position: relative;
		// top: 50px;

		margin-bottom: -10%;
		padding: 2.95% 2.55% 0;

		background-image: url(../img/device-desktop.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__image--tablet {

		bottom: 0;
		left: 0;
	    position: absolute;

		margin-bottom: -15%;
		position: absolute;
		padding: 2.45% 1.7% 0;
		width: 35%;

		background-image: url(../img/device-tablet.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__image--phone {

		bottom: 0;
		margin-bottom: -5%;
		position: absolute;
		padding: 2.55% 1.3% 0;
		right: 0;
		width: 15%;

		background-image: url(../img/device-phone.png);
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__details {

	}

	&__title {

		color: #fff;
	}

	&__button {

	}
}