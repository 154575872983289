.features-list {

    &__feature {

    	margin-bottom: $spacer-5;
    	overflow: hidden;

    	@include media-breakpoint-up(xxl) {
    		margin-bottom: $spacer-7;
    	}
    }

	&__feature:nth-child(odd) &__image {
		@include media-breakpoint-up(md) {
			margin-left: -30px;
		}
	}

	&__feature:nth-child(even) &__image {
		@include media-breakpoint-up(md) {
			position: relative;
			right: -80px;
		}
	}

    &__description {

		padding: $spacer-4 0 0;
    }

    &__heading {

    }

    &__paragraph {

    }
}