/*
|--------------------------------------------------------------------------
| Antialias
|
| Clean up fonts for webkit and OSX as needed. Typicall this is to be used
| sparingly.
|--------------------------------------------------------------------------
*/

@mixin antialias {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
