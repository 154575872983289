.site-logo {

    display: block;
    margin-bottom: $spacer;
    max-width: 150px;

    text-decoration: none;

    @include media-breakpoint-down(xs){

        margin: $spacer*2 auto $spacer;
    }

    @include media-breakpoint-up(md) {
        order: 1;
    }

    &__image-wrap {

        @include aspect-ratio(474,232);
    }

    &__image {

        max-width: 100%;
    }
}
