.signup-card {

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: $spacer-4;
  width: 100%;

  background-color: $yellow;

  &--free {

  }

  &--diy {

    background-color: $mustard;
  }

  &--setup {

    background-color: $green;
  }

  &__header {

    margin-bottom: $spacer-4;
    padding: $spacer-4 $spacer-1 $spacer-3;
    width: 100%;

    background-color: rgba(#fff, .15);

    @include media-breakpoint-up(lg) {
      padding: $spacer-4 $spacer-1;
    }

    @include media-breakpoint-up(xl) {
      padding: $spacer-4 $spacer-1;
    }

    @include media-breakpoint-up(xxl) {
      padding: $spacer-5 $spacer-1 $spacer-4;
    }
  }

  &__heading {

    @extend .h5;

    color: #fff;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 24px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 28px;
    }
  }

  &__price {

    @extend .h2;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;

    font-weight: 700;
    text-align: center;

    @include media-breakpoint-between(md,md) {
          flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 48px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 54px;
    }
  }


  &__points {

    margin-bottom: $spacer-3;
    margin-left: $spacer-4;
    padding: 0 $spacer-3;
  }

  &__point {

    position: relative;

    list-style: none;
    margin-bottom: $spacer/1.5;

    font-weight: 300;

    &:before {
      position: absolute;
      left: -30px;
      top: 2px;

      content: '';
      display: inline-block;
      height: em(8);
      width: em(18);

      font-size: 18px;

      border-bottom: 2px solid $gray-dark;
      border-left: 2px solid $gray-dark;
      transform: rotate(-45deg);
    }
  }

  &__button {

    margin-top: auto;
  }
}