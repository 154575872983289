/*
|--------------------------------------------------------------------------
| Main Nav
|
| Using the label and input as for the mobile version.
|--------------------------------------------------------------------------
*/

.main-nav {

    order: 1;

    background-color: $gray;

    @include media-breakpoint-down(sm){
        left: -15px;
        position: relative;

        height: 40px;
        width: 40px;
    }

    @include media-breakpoint-up(md) {

        order: 3;
        left: 0;
    }

    @include media-breakpoint-up(lg) {

        background-color: transparent;
    }

    @include media-breakpoint-up(xl){

    }

    &__list {

        @include list-unstyled;
        margin-top: $spacer;

        background-color: $gray;
        color: #fff;
        text-align: left;

        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 40px;
            z-index: 10;
            width: 100vw;

            transition: transform .25s ease-out;
            transform: scaleY(0);
            transform-origin: 75% top;
        }

        @include media-breakpoint-up(md) {
            width: 100%;
        }


        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
        }

        @include media-breakpoint-up(xxl){
            justify-content: space-between;
            width: 460px;
        }
    }

    &__item {
        display: block;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        padding: 10px;

        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            width: auto;

            font-size: 12px;
        }

        @include media-breakpoint-up(xl){
            font-size: 16px;
        }

        @include media-breakpoint-up(xxl){
            font-size: 20px;
        }

        &:hover, &:focus {

            outline: 0;
            text-decoration: none;
        }
    }

    &__item--active &__link {

        color: $yellow;
    }

    &__trigger {
        left: auto;
        position: absolute;
        right: -10px;
        top: 10px;

        margin: 0;
        padding: 14px 0;
        width: 100%;
        z-index: 5000;

        appearance: none;
        -webkit-appearance: none;
        border: none;
        background-color: transparent;
        color: #fff;
        opacity: 1;
        outline: 0 !important;
        text-align: center;

        @include media-breakpoint-up(md){
            display: none;
        }

        &:after, &:before {

            position: absolute;
            left: 0;
            top: 0;

            content:'';
            width: 25px;

            transition: all .2s;
        }

        &:before {

            height: 10px;

            border-bottom: 2px solid #fff;
            border-top: 2px solid #fff;
        }

        &:after {

            top: 16px;

            border-bottom: 2px solid #fff;
            text-shadow: none !important;
            font-size: 1.8em;
            $font-family: $font-family-sans-serif;
            line-height: 1;
            opacity: 1;
        }

        &:checked:before {

            left: 3px;
            top: 6px;

            transform: rotate(-45deg);

            border-bottom: none;
        }

        &:checked:after {

            left: 0px;
            top: 7px;

            transform: rotate(45deg);
        }
    }

    &__trigger:checked ~ &__list {
        display: block;

        transform: scaleY(1);
    }
}
