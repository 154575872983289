.site-credit {

    width: 100%;

    padding-bottom: $spacer;
    text-align: center;

    &__list {

        @include list-unstyled;

        width: 100%;
    }

    &__item {

        @include list-unstyled;

        display: inline-block;
        margin-right: 10px;
    }

    &__link {

        font-size: 70%;
        text-decoration: none;
    }
}
