.email-signup {
  background-color: $gray-light;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

  &__form {

    width: 100%;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding: $spacer-3 $spacer-1 0;
    }
  }

  &__input {

    border: none;

    @include media-breakpoint-down(md) {
      margin: auto;
      width: 100%;

      text-align: center;
    }
  }

  &__button {

    @include media-breakpoint-down(md) {
      margin: auto;
      width: 100%;
    }
  }
}