.page-hero {

    padding: $spacer*2 0 0;

    @include media-breakpoint-up(xl) {
    	margin-bottom: $spacer-4;
    }

    &__heading {
        padding-top: 6vh;
    }
}