.footer-nav {

    margin-bottom: $spacer;
    padding: $spacer-3 0 $spacer;
    width: 100%;

    color: #fff;
    text-align: center;

    &__list {

        @include list-unstyled;

        max-width: 800px;
        margin: 0 auto;

        text-align: center;

        @include media-breakpoint-up(md){
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {

        display: block;
        padding: 10px 0;
    }

    &__link {

        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}